import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { path } from 'ramda'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  PinBlockGroup,
  AggregatorPin4,
  CategoryTemplate,
  Typography,
} from '../../components'

const propTypes = {
  data: PropTypes.shape({
    category: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        title: PropTypes.string,
        summary: PropTypes.string,
        content: PropTypes.string,
      }),
    }),
    publications: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              excerpt: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),
}

export const PublicationCategoryTemplate = ({ data }) => {
  const {
    category: {
      frontmatter: { title, summary, content },
    },
    publications: { edges: publications },
  } = data

  const breadcrumbs = [
    {
      label: 'Research',
      url: '/researching',
    },
    {
      label: title,
    },
  ]

  return (
    <MDXProvider>
      <CategoryTemplate breadcrumbs={breadcrumbs}>
        <Divider thickness={4} />
        <Typography tag="h1" type="boxHeading" className="mb-8">
          {title}
        </Typography>
        {summary && <div>{summary}</div>}
        {content && <MDXRenderer>{content}</MDXRenderer>}
        {publications.length > 0 && (
          <PinBlockGroup columns={2}>
            {publications.map(({ node }, i) => {
              return (
                <AggregatorPin4
                  key={i}
                  title={path(['frontmatter', 'title'], node)}
                  thumbnailSrc={path(['frontmatter', 'featuredImage'], node)}
                  href={path(['fields', 'slug'], node)}
                >
                  {path(['frontmatter', 'excerpt'], node)}...
                </AggregatorPin4>
              )
            })}
          </PinBlockGroup>
        )}
      </CategoryTemplate>
    </MDXProvider>
  )
}

PublicationCategoryTemplate.propTypes = propTypes

const PublicationCategory = ({ data }) => {
  const {
    category: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <PublicationCategoryTemplate data={data} />
    </LayoutFullWidth>
  )
}

PublicationCategory.propTypes = propTypes

export default PublicationCategory

/** @todo only fetch publications by category */
export const pageQuery = graphql`
  query PublicationCategoryTemplate($slug: String!, $title: String) {
    category: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
        title
      }
    }
    publications: allMdx(
      filter: {fields: {contentType: {eq: "publications"}}, frontmatter: {categories: {in: [$title]}}},
      sort: { fields: frontmatter___publishedDate, order: DESC },
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  base64
                  height
                  src
                  srcSet
                  width
                }
              }
            }
            categories
          }
        }
      }
    }
  }
`
